import React, { useState, useEffect } from "react";
import { Heart, Gift, Building2, Share2 } from "lucide-react";
import { useParams } from "react-router-dom";
import { getPublicTributeDetails } from "./service";
import logo_green from "./assets/logo_green.svg";
import { useNavigate } from 'react-router-dom';

const DefaultNpoLogo = () => (
  <div className="h-16 w-16 rounded-xl bg-teal-50/50 p-3 flex items-center justify-center">
    <Building2 className="w-full h-full text-[#2F4F4F]" />
  </div>
);

const PublicTribute = ({ tributeDetails }) => {
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#E6EDED]/5 to-white">
      {/* Main Container */}
      <div className="max-w-2xl mx-auto px-4 py-12 space-y-12">
        {/* Logo */}
        <div
          onClick={() => { navigate("/") }}
          className="flex justify-center cursor-pointer">
          <div className="flex flex-col items-center space-y-2 ">
            <img src={logo_green} alt="TinyTribute" className="h-12 w-12 " />
            <div className="flex items-center text-xl">
              <span className="font-medium  text-gray-600">Tiny</span>
              <span className="font-extrabold" style={{ color: '#2F4F4F' }}>Tribute</span>
            </div>
          </div>
        </div>

        {/* Main Card */}
        <div className="bg-white rounded-2xl shadow-sm border border-gray-100 overflow-hidden pt-5">

          {/* Header */}
          <div className="text-center">
            <div className="inline-flex items-center mb-3">
              <span className="text-[12px] font-bold text-[#2F4F4F] uppercase">
                In Honor of
              </span>
            </div>

            <div className="space-y-3">
              <h1 className="text-4xl font-bold text-gray-900 tracking-tight">
                {tributeDetails?.honoree_name}
              </h1>
              {tributeDetails?.honoree_company && (
                <p className="text-[16px] text-gray-600">
                  {tributeDetails?.honoree_company}
                </p>
              )}
            </div>
          </div>

          {/* Donor Section */}
          <div className="px-8 py-10 border-b border-gray-100 text-center">
            <p className="text-sm text-gray-500 mb-1">By</p>
            <h2 className="text-2xl font-semibold text-gray-900">
              {tributeDetails?.user?.name}
            </h2>
          </div>

          {/* Donation Header */}
          <div className="px-8 pt-8 border-b border-gray-100 text-center bg-[#2F4F4F]/5">
            <div className="flex flex-col pb-4">
              <div className="flex items-center justify-center space-x-2">
                <Gift className="w-5 h-5 text-[#2F4F4F]" />
                <span className="text-sm font-semibold text-[#2F4F4F] uppercase">Donation made to</span>
              </div>
              <p className="text-[12px] font-medium text-gray-500 mt-[6px]">
                {formatDate(tributeDetails?.created_at)}
              </p>
            </div>
          </div>

          {/* Organization Section */}
          <div className="px-8 pt-4 py-8 bg-[#2F4F4F]/5">
            <div className="max-w-md mx-auto space-y-6 text-center">
              <div className="flex items-center justify-center">
                {tributeDetails?.npo?.logoUrl ? (
                  <img
                    src={tributeDetails?.npo?.logoUrl}
                    alt={tributeDetails?.npo?.name}
                    className="h-20 w-20 object-contain rounded-xl bg-white p-3 shadow-sm"
                  />
                ) : (
                  <DefaultNpoLogo />
                )}
              </div>

              <div className="text-center space-y-3">
                <a href={tributeDetails?.npo?.url} target="_blank">
                  <h3 className="text-xl font-semibold text-gray-900 hover:underline">
                    {tributeDetails?.npo?.name}
                  </h3>
                </a>
                <p className="text-sm text-gray-600">
                  Empowering children's rights and creating lasting change
                </p>
              </div>
            </div>
          </div>

          {/* Message Section */}
          {tributeDetails?.public_visibility === "full" &&
            tributeDetails?.tribute_canvas_data?.canvasImage && (
              <div className="px-8 py-8 border-t border-gray-100">
                <div className="space-y-4">
                  <div className="rounded-xl overflow-hidden bg-gray-50">
                    <img
                      src={tributeDetails?.tribute_canvas_data?.canvasImage}
                      alt="Tribute message"
                      className="w-full object-contain"
                    />
                  </div>
                </div>
              </div>
            )}
        </div>


      </div>

      {/* Footer */}
      <div className="text-center pb-6 flex flex-row items-center justify-center">
        <p className="text-[10px] text-gray-500 mr-[4px]">Powered by</p>
        <div className="flex items-center cursor-pointer">
          <img
            src={logo_green}
            className="h-[14px] w-[14px] mr-1"
            alt="TinyTribute"
          />
          <span className="text-[14px] text-black font-semibold select-none">
            TinyTribute
          </span>
        </div>
      </div>
    </div>
  );
};

// Main container component with loading handling
const PublicTributeContainer = () => {
  const [tributeDetails, setTributeDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const { tributeId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getPublicTributeDetails({ tributeId });
        setTributeDetails(response);
      } catch (error) {
        console.error("Error fetching tribute details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [tributeId]);

  if (loading) return <Loader />;

  return <PublicTribute tributeDetails={tributeDetails} />;
};

const Loader = () => (
  <div className="flex justify-center items-center h-32">
    <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-teal-500"></div>
  </div>
);

export default PublicTributeContainer;
